<template>
  <div>
    <CModal
        title="New Product"
        size="lg"
        :show.sync="showModal"
        @update:show="closeAddModal"
    >
      <CAlert
          v-if="error"
          color="danger"
          closeButton
          @update:show="removeError">
        {{error}}
      </CAlert>
      <ProductForm
          v-bind:productIn="product"
          v-on:update-item="updateItem"
          v-on:error="catchError"
      />
    </CModal>
  </div>
</template>

<script>
import gql from "graphql-tag";
import ProductForm from "@/apps/contracts/components/ProductForm";

const createProduct = gql`
  mutation createProduct($product: ProductInput){
    createProduct(product: $product) {
      nid
      name
      description
      productGroup {
        name
        description
      }
    }
  }
`

export default {
  name: "ProductAdd",
  components: {
    ProductForm
  },
  data(){
    return {
      product: {},
      productSave: {},
      error: null
    }
  },
  computed: {
    showModal: {
      get: function() {
        return this.$store.state.addModalShow
      },
      set: function (){
      }
    }
  },
  methods: {
    updateItem(data){
      this.productSave = data
    },
    closeAddModal(status, evt, accept){
      if (accept) {
        this.$apollo.mutate({
          mutation: createProduct,
          variables: {
            product: this.productSave
          },
        })
        .then(res => {
          this.$emit('add-item', res.data.createProduct);
          this.product = {};
          this.productSave = {};
          this.$store.commit('set', ['addModalShow', false]);
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
        })
       }else{
        this.product = {};
        this.productSave = {};
        this.$store.commit('set', ['addModalShow', false]);
       }
    },
    catchError(event){
      this.error = event
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    }
  }
}
</script>
